<style lang="scss">
  .tab-button{
    &.active{
      font-weight:bold;
    }
  }

  .vc-chrome-toggle-btn {
    display: none !important;
  }

  .separate-section{
    border: 1px solid #ededed;
    border-width: 1px;
    border-radius: 4px;
    background:#fff;
  }
</style>
<template>
  <div
    id="outer-wrapper"
    @click="onClickBody"
  >
    <vue-headful
      :title="`${metaTitle} | Octoa`"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :user-might-update.sync="userMightUpdate"
      redirect="/templates/questionnaires"
      @click="alertExit"
    />

    <v-video-modal
      name="lead"
      title="Questionnaire creation"
      description="A video to help you get started."
      step="leadform-edit"
      thumbnail="lead-form.png"
      embed="2ItCnf1rz1E"
      :show.sync="isShowVideoModal"
      @close="closeVideoModal"
    />

    <div class="max-w-md mx-auto px-4">
      <div class="flex -mx-2 lead-form-edit-container mb-10">
        <div
          v-if="loading"
          class="w-full px-2 text-center"
        >
          <img
            src="@/assets/img/icons/loader.svg"
            width="50"
            class="mt-32 text-center"
          >
        </div>
        <div
          v-else
          id="lead-form-setting"
          class="w-full px-2 mt-16"
        >
          <div class="bg-white shadow rounded px-8 py-8">
            <div class="flex items-center justify-between mb-2">
              <div class="w-1/2 md:w-2/3">
                <h1 class="text-xl">
                  Questionnaire
                </h1>
              </div>
              <div class="w-1/2 md:w-1/3 text-right">
                <div class="inline-block">
                  <!-- <v-tutorial-button @click="showVideoModal" /> -->
                </div>
              </div>
            </div>
            <p class="text-sm mb-3 text-grey-dark">
              Give this template a name.
            </p>
            <input
              v-model="formSetting.name"
              v-validate="{ required: true }"
              type="text"
              class="w-full"
              name="name"
              placeholder="Template name"
            >
            <span class="error inline-block mt-2">{{ errors.first('name') }}</span>


          </div>
          <div id="lead-form">
            <div class="lead-form-edit">
              <div class="flex heading text-center">
                <div
                  class="w-1/2 tab-button"
                  :class="{'active' : view == 'create'}"
                  @click="changeView('create')"
                >
                  Create
                </div>
                <div
                  class="w-1/2 tab-button"
                  :class="{'active' : view == 'preview'}"
                  @click="changeView('preview')"
                >
                  Preview
                </div>
              </div>
              <div
                v-show="view == 'create'"
                class="py-5"
              >
                <h1 class="text-xl mb-2 px-8">
                  Create Questionnaire
                </h1>
                <div class="px-8 mt-5 mb-3">
                  <v-checkbox
                    id="showLogo"
                    v-model="formSetting.showLogo"
                    :checked="formSetting.showLogo"
                    label="Show my company logo on top of the questionnaire"
                  />
                </div>
                <div class="px-8 mt-5 mb-3">
                  <v-checkbox
                    id="isRedirectOn"
                    v-model="formSetting.isRedirectOn"
                    :checked="formSetting.isRedirectOn"
                    label="Redirect to another page after submitting the form"
                  />
                </div>
                <div
                  v-show="formSetting.isRedirectOn"
                  class="px-8"
                >
                  <input
                    v-model="formSetting.redirectLink"
                    type="text"
                    placeholder="Enter a website URL"
                  >
                </div>
                <div
                    class="pb-4 mt-5 content px-8 text-sm"
                  >
                  <p class="text-sm mt-5 mb-3 ">
                    Give it a title which your client will see.
                  </p>
                  <input
                    v-model="formSetting.title"
                    v-validate="{ required: true }"
                    type="text"
                    class="w-full"
                    name="title"
                    placeholder="Title of the questionnaire"
                  >
                  <span class="error inline-block mt-2">{{ errors.first('title') }}</span>
                </div>
                <div
                  class="pb-4 content px-8 text-sm"
                >
                  Welcome message (optional)
                  <textarea-autosize
                    v-model="formSetting.welcomeMessage"
                    placeholder="Show a welcome message at the top of the form."
                    class="block mt-3 w-full"
                    :min-height="85"
                    :max-height="100"
                  />
                </div>

                <div
                  v-show="!formSetting.isRedirectOn"
                  class="pb-8 content px-8 text-sm"
                >
                  Thank you message
                  <textarea-autosize
                    v-model="formSetting.successMessage"
                    placeholder="Show a thank you message when a potential client filled in your form."
                    class="block mt-3 w-full"
                    :min-height="85"
                    :max-height="100"
                  />
                  <span
                    v-show="successMessageError"
                    class="error inline-block mt-2"
                  >Please enter a message to show when a client has submitted the form.</span>
                </div>
              </div>

              <div
                v-show="view == 'preview'"
                class="py-5 content text-sm"
              >
                <div
                  class="px-3 mx-8 rounded py-6"
                >
                  <div
                    v-if="listofFields.length < 1"
                    class="text-center my-10"
                  >
                    Add some fields to see a preview of your questionnaire.
                  </div>

                  <div
                    v-if="listofFields.length > 0 && logo.path"
                    class="logo text-center"
                  >
                    <img
                      :src="logo.path"
                      width="100%"
                      :style="[logo.width > logo.height ? {'max-width' : '278px'} : '', logo.width < logo.height ? { 'width' : 'auto', 'max-height':'250px'} : '', logo.width == logo.height ? { 'width' : '88px', 'height':'88px'} : '' ]"
                    >
                  </div>

                  <div
                      v-if="listofFields.length"
                      class="my-10 px-8"
                    >
                      <h1
                        v-if="formSetting.title != ''"
                        class="mb-3"
                      >
                        {{ formSetting.title }}
                      </h1>
                      <p v-if="formSetting.welcomeMessage != ''">
                        {{ formSetting.welcomeMessage }}
                      </p>
                    </div>

                  <div
                    v-for="item in listofFields"
                    :key="item.id"
                    class="fields-container "
                  >
                    <div class="fields-preview mb-2 px-8 active">
                      <div class="input">
                        <div
                          v-if="item.type != 'staticText'"
                          class="flex w-full"
                        >
                          <div class="w-1/2">
                            <div
                              class="mb-2"
                            >
                              {{ item.label }}
                            </div>
                          </div>
                          <div class="w-1/2 text-right">
                            <span
                              v-if="item.is_required"
                              class="text-grey-semi-light"
                            >Required</span>
                          </div>
                        </div>
                        <div v-if="item.type == 'text'">
                          <input
                            type="text"
                            class="lead"
                          >
                        </div>
                        <div v-if="item.type == 'datepicker'">
                          <v-date-picker
                            class="datepicker"
                            v-model="fakeDate"
                            :popover="{ visibility: 'click' }"
                            :masks="{L: 'WWW, MMM D, YYYY'}"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                type="text"
                                :value="inputValue"
                                v-on="inputEvents"
                              />
                            </template>
                          </v-date-picker>
                        </div>
                        <div v-if="item.type == 'textarea'">
                          <textarea
                            class="lead"
                          />
                        </div>
                        <div
                          v-if="item.type == 'staticText'"
                          v-html="item.label"
                        />
                        <div v-if="item.type == 'dropdown'">
                          <div
                            class="select-container tall w-full"
                          >
                            <select>
                              <option
                                v-for="option in item.options"
                                :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div v-if="item.type == 'checkbox'">
                          <div
                            v-for="option in item.options"
                            class="mb-2"
                            :value="option"
                          >
                            <v-checkbox
                              :name="item.label"
                              :label="option"
                            />
                          </div>
                        </div>
                        <div
                          v-if="item.is_required"
                          class="error mt-1"
                        >
                          The {{ item.label | formatToLowerCase }} field is required.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="listofFields.length > 0"
                    class="text-center"
                  >
                    <input
                      type="submit"
                      name="submit"
                      value="Submit"
                      class="btn green-btn"
                    >
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                v-show="view == 'create'"
                class="content text-sm"
              >
                <!-- Start Custom -->
                <div v-show="listofFields.length > 0">
                  <!-- Draggable -->
                  <draggable
                    :list="listofFields"
                    handle=".drag"
                    drag-class="drag-helper"
                    animation="200"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <div
                        v-for="(item, index) in listofFields"
                        :key="index"
                      >
                        <div
                          class="draggable-item bg-white rounded"
                          @click="toggleActive(item)"
                        >
                          <div class="fields-container">
                            <div
                              v-if="item.isActive"
                              class="fields-edit pt-3 pb-5 px-8 mb-5 "
                            >
                              <div class="flex flex-wrap border-grey-lighter border-b pb-3">
                                <div class="drag w-full">
                                  <div class="icon pt-1 pb-3 text-center">
                                    <img src="@/assets/img/icons/drag.svg">
                                  </div>
                                </div>
                                <div class="w-full">
                                  <div class="flex flex-wrap">
                                    <div
                                      v-if="item.type != 'staticText'"
                                      class="w-full md:w-1/2"
                                    >
                                      <div
                                        class="input-group mb-2"
                                      >
                                        <label class="inline-block w-24"> Field label</label>
                                        <input
                                          v-model="item.label"
                                          type="text"
                                          class="w-full max-w-xs mb"
                                          placeholder="Label (ex. Your email address)"
                                        >
                                      </div>
                                    </div>
                                    <div
                                      v-else
                                      class="w-full"
                                    >
<!--                                      <textarea-->
<!--                                        v-model="item.label"-->
<!--                                        placeholder="Static text block"-->
<!--                                        maxlength="511"-->
<!--                                      />-->
                                       <v-ckeditor v-model="item.label" />
                                      <div class="w-full text-right my-3">
                                        <span
                                          class="field-type-label uppercase"
                                        >Static text</span>
                                      </div>
                                    </div>
                                    <div class="w-full md:w-1/2 text-right mb-3 md:mb-0">
                                      <span
                                        v-if="item.type == 'text'"
                                        class="field-type-label uppercase"
                                      >Short question</span>
                                      <span
                                        v-if="item.type == 'textarea'"
                                        class="field-type-label uppercase"
                                      >Long question</span>
                                      <span
                                        v-if="item.type == 'datepicker'"
                                        class="field-type-label uppercase"
                                      >Date</span>
                                      <span
                                        v-if="item.type == 'dropdown'"
                                        class="field-type-label uppercase"
                                      >Single choice</span>
                                      <span
                                        v-if="item.type == 'checkbox'"
                                        class="field-type-label uppercase"
                                      >Multiple choice</span>
                                    </div>
                                  </div>
                                  <div
                                    v-if="item.type == 'checkbox' || item.type == 'dropdown'"
                                    class="input-group mb-2"
                                  >
                                    <label class="inline-block w-24">Items</label>

                                    <div v-for="(option, optionIndex) in item.options">
                                      <div class="flex items-center">
                                        <div class="mb-2 w-full md:w-4/12">
                                          <input
                                            v-model="item.options[optionIndex]"
                                            type="text"
                                            class="w-full max-w-xs mb"
                                            placeholder="Options"
                                          >
                                        </div>
                                        <div class="ml-2">
                                          <img
                                            src="@/assets/img/icons/ico-delete-green.svg"
                                            width="15"
                                            class="mb-1"
                                            @click="removeDropdownOption(index, optionIndex)"
                                          >
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="text-green underline mt-1"
                                      @click="addDropdownOption(index)"
                                    >
                                      + Add option
                                    </div>
                                  </div>
                                  <div
                                    v-if="!item.is_mapping_fix && item.type != 'checkbox' && item.type != 'staticText'"
                                    class="input-group mb-2"
                                  >
                                    <label class="inline-block">
                                      Save data to <v-help-tooltip
                                        :options="tooltipContent"
                                      />
                                    </label>
                                    <div
                                      v-if="!item.is_mapping_fix"
                                      class="select-container tall "
                                    >
                                      <select
                                        v-model="item.mapping"
                                        class="w-32"
                                        @change="changeMapping(item.id)"
                                      >
                                        <option value="nothing">
                                          Nothing
                                        </option>
                                        <option
                                          v-for="map in mapping_options"
                                          v-if="(map.value == 'date' && item.type =='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                          :key="map.id"
                                          :value="map.value"
                                        >
                                          {{ map.label }}
                                        </option>
                                        <option
                                          v-for="map in mapping_options"
                                          v-if="(map.value != 'date' && item.type !='datepicker') && (map.assignedTo == item.id || map.assignedTo == null)"
                                          :key="map.id"
                                          :value="map.value"
                                        >
                                          {{ map.label }}
                                        </option>
                                      </select>
                                    </div>
                                    <div v-else>
                                      <div class="select-container tall">
                                        <select
                                          v-model="item.mapping"
                                          class="capitalize w-32"
                                        >
                                          <option :value="item.mapping">
                                            {{ item.mapping }}
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="flex flex-row-reverse text-right">
                                <div class="pt-3">
                                  <a
                                    v-if="!item.is_mapping_fix"
                                    class="underline blue mr-2 pr-2 pt-3 pb-2 border-r border-grey-lighter"
                                    @click="removeAdditionalFields(index)"
                                  >Delete</a>
                                  <v-checkbox
                                    v-if="!item.is_mapping_fix"
                                    :id="`listofFields${index}`"
                                    v-model="item.is_required"
                                    :active="(item.is_mapping_fix) ? 'disabled' : ''"
                                    label="Required"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              v-else
                              class="fields-preview mb-5 py-5 px-8 active"
                            >
                              <div class="input">
                                <div v-if="item.type != 'staticText'">
                                  <div class="flex w-full">
                                    <div class="w-1/2">
                                      <div class="mb-3">
                                        {{ item.label }}
                                      </div>
                                    </div>
                                    <div class="w-1/2 text-right">
                                      <span
                                        v-if="item.is_required"
                                        class="text-grey-semi-light"
                                      >Required</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="item.type == 'text'">
                                  <input
                                    type="text"
                                    class="lead"
                                    disabled="disabled"
                                  >
                                </div>
                                <div v-if="item.type == 'datepicker'">
                                  <input
                                    type="text"
                                    class="lead"
                                    disabled="disabled"
                                  >
                                </div>
                                <div v-if="item.type == 'textarea'">
                                  <textarea
                                    class="lead"
                                    disabled="disabled"
                                  />
                                </div>
                                <div
                                  v-if="item.type == 'staticText'"
                                  class="leading-normal"
                                  v-html="item.label"
                                />
                                <div
                                  v-if="item.type == 'checkbox'"
                                  class="leading-normal"
                                >
                                  <div
                                    v-for="option in item.options"
                                    class="mb-2"
                                    :value="option"
                                  >
                                    <v-checkbox
                                      :name="item.label"
                                      :label="option"
                                    />
                                  </div>
                                </div>
                                <div v-if="item.type == 'dropdown'">
                                  <div
                                    class="select-container tall w-full"
                                  >
                                    <select disabled="disabled">
                                      <option
                                        v-for="option in item.options"
                                        :value="option"
                                      >
                                        {{ option }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition-group>
                  </draggable>
                  <!-- End Draggable -->
                </div>
                <div class="mt-8 mb-5 bg-white rounded px-8 py-6">
                  <h1 class="text-lg mb-6">
                    Choose a question type
                  </h1>
                  <div class="flex flex-wrap items-center">
                    <div class="w-full">
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('text')"
                      >
                        + Short question
                      </a>
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('textarea')"
                      >
                        + Long question
                      </a>
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('dropdown')"
                      >
                        + Single choice
                      </a>
                    </div>
                    <div class="w-full">
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('staticText')"
                      >
                        + Static text
                      </a>
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('checkbox')"
                      >
                        + Multiple Choice
                      </a>
                      <a
                        class="inline-block green-btn mr-3 mb-3"
                        @click="addAdditionalFields('datepicker')"
                      >
                        + Date
                      </a>
                    </div>
                  </div>
                </div>

                <!-- End custom item -->
              </div>
              <!-- End create view -->
              <div
                class="text-right mt-8 mb-12"
              >
                <a
                  v-show="!isSaving"
                  class="green-btn"
                  @click="save"
                >
                  Save
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import auth from '@/auth'
import { logException } from '@/helpers'

export default {
  components: {
    draggable,
  },
	data(){
    	return {
        logo: {path:'', width: 0, height: 0 },
        metaTitle: 'Loading',
        view: 'create',
        formId: null,
        fakeDate: null,
        loading: false,
        mapping_options:[
        {
          label: 'Name',
          value: 'name',
          assignedTo: null,
        },
        {
          label: 'Email',
          value: 'email',
          assignedTo: null,
        },{
          label: 'Date',
          value: 'date',
          assignedTo: null,
        },{
          label: 'Location',
          value: 'location',
          assignedTo: null,
        },{
          label: 'Company',
          value: 'company',
          assignedTo: null,
        },{
          label: 'Phone',
          value: 'phone',
          assignedTo: null,
        },{
          label: 'Address',
          value: 'address',
          assignedTo: null,
        },{
          label: 'City',
          value: 'city',
          assignedTo: null,
        },{
          label: 'Country',
          value: 'country',
          assignedTo: null,
        },{
          label: 'Postal code',
          value: 'postal_code',
          assignedTo: null,
        },{
          label: 'VAT',
          value: 'vat',
          assignedTo: null,
        }],
        formSetting: {
          name: '',
          title: '',
          showLogo: true,
          welcomeMessage: '',
          successMessage: '',
          isRedirectOn: false,
          redirectLink: '',
        },
    		listofFields: [],
        listofForms: {},
    		success: false,
        embedLink: null,
        isSaving: false,
        isSaved: false,
        successMessageError: false,
        user: auth.user(),
        isShowVideoModal: false,
        tooltipContent: {
          content: `
            Store the input of this field to your contact sheet to the selected field. When selecting nothing you will still see it in your email and inside the project.
          `
        },
        userMightUpdate: false,
    	}
	},
  async mounted(){
    await this.checkIntroVideo()
    if(this.$route.params.templateId){
      this.loading = true
      await this.getForm(this.$route.params.templateId)
      this.metaTitle = `Template Questionnaire: ${this.formSetting.name}`
    } else {
      this.metaTitle = 'New Template Questionnaire'
    }

    this.logo.path = this.user.companySettings.logo
    let that = this
    let image = new Image()
    image.src = this.logo.path
    image.onload = function () {
      that.logo.width = this.width
      that.logo.height = this.height
    }
  },
	methods: {
    async alertExit(){
      if(this.userMightUpdate){
        let link = '/templates/questionnaires'

        const canDelete = await this.$alert.confirm({
          title: 'Are you sure?',
          text: 'You might have unsaved changes, remember to save before closing.',
        })

        if (canDelete) {
          this.$router.push(link)
        }
      }
    },
    removeDropdownOption(index, optionIndex){
      this.listofFields[index].options.splice(optionIndex, 1)
    },
    addDropdownOption(index){
      this.listofFields[index].options.push('')
    },
    changeMapping(targetId){
      let that = this
      this.listofFields.forEach(function(item) {
        that.mapping_options.forEach(function(map) {
          if(item.mapping == map.value){
            map.assignedTo = item.id
          }
        })
      })
    },
    addAdditionalFields(type) {
      this.listofFields.forEach(function(item) {
        item.isActive = false
      })
      this.listofFields.push({
          id: new Date().getTime(),
          isActive: true,
          label: '',
          type: type,
          mapping: 'nothing',
          options: [],
          is_mapping_fix: false,
          is_required: false,
      })

      this.userMightUpdate = true
    },
    removeAdditionalFields(index){
      let field = this.listofFields[index]
      this.mapping_options.forEach(map => {
        if(map.assignedTo == field.id){
          map.assignedTo = null
        }
      })
      this.listofFields.splice(index, 1)
      this.userMightUpdate = true
    },
    toggleActive(target){
      this.listofFields.forEach(function(item) {
        item.isActive = false
        if(item.id == target.id){
          item.isActive = true
        }
      })
    },
		save() {

      let that = this
      let error = false

      let urlPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i') // fragment locator

      this.$toasted.clear()

      if(this.formSetting.name < 3){
        this.$toasted.global.general_error({
          message : 'Please add a name to the template.'
        })
        return false
      }

      if(this.formSetting.title < 3){
        this.$toasted.global.general_error({
          message : 'Please add a title to the template.'
        })
        return false
      }

      this.successMessageError = false
      if(this.formSetting.isRedirectOn){
        if(!urlPattern.test(this.formSetting.redirectLink)){
          this.$toasted.global.general_error({
            message : 'Please enter a valid URL with http:// or https://'
          })
          return false
        }
      } else if(that.formSetting.successMessage.length < 3){
        this.$toasted.global.general_error({
          message : 'Thank you message cannot be empty.'
        })
        this.successMessageError = true
        error = true
        return false
      }

      if(this.listofFields.length < 1){
        this.$toasted.global.general_error({
          message : 'Please add at least one field.'
        })
        return false
      }

      this.listofFields.forEach(function(item) {
        let message = 'Field label cannot be empty.'
        if(item.label == ''){
          if(item.type == 'staticText'){
            message = 'Static message cannot be empty.'
          }
          that.$toasted.global.general_error({
            message : message
          })
          error = true
        }
      })

      if (error) {
        return false
      }

      this.doSave()
		},
    async doSave(){
        this.saving = true

        try {
          const postData = {
            companyId: this.user.company.id,
            formId: this.formId,
            name: this.formSetting.name,
            title: this.formSetting.title,
            showLogo: this.formSetting.showLogo,
            isRedirectOn: this.formSetting.isRedirectOn,
            redirectLink: this.formSetting.redirectLink,
            welcomeMessage: this.formSetting.welcomeMessage,
            successMessage: this.formSetting.successMessage,
            fields: this.listofFields,
          }

          const { data } = await this.$api.get('templateQuestionnaire').updateOrCreate(postData)

          this.formId = data.questionnaire.id
          this.isSaved = true
          this.saving = false
          this.userMightUpdate = false

          this.$toasted.global.general_success({
            message : 'Questionnaire template saved.'
          })

        } catch(e){
          logException(e)
        }
      },
    changeView(view){
      this.view = view
    },
    async getForm(id){

      this.loading = true

      try {
        const { data } = await this.$api.get('templateQuestionnaire').detail(this.user.company.id, id)
          this.formId = data.questionnaire.id
          this.formSetting.name = data.questionnaire.name
          this.formSetting.title = data.questionnaire.title
          this.formSetting.welcomeMessage = data.questionnaire.welcome_message
          this.formSetting.successMessage = data.questionnaire.success_message
          this.formSetting.showLogo = data.questionnaire.show_logo
          this.formSetting.isRedirectOn = data.questionnaire.is_redirect_on
          this.formSetting.redirectLink = data.questionnaire.redirect_link

          this.listofFields = []

          data.questionnaire.fields.forEach(field => {
            let options = []
            if(field.options){
              options = field.options
            }
            this.listofFields.push({
              id: field.id,
              isActive: false,
              label: field.label,
              type: field.type,
              mapping: field.mapping,
              options: options,
              is_mapping_fix: field.is_mapping_fix,
              is_required: field.is_required,
            })
          })

          this.loading = false
      } catch(e){
        logException(e)
      }
    },
    onClickBody(event){
      let that = this
      if(event.target.id == 'outer-wrapper'){

        that.listofFields.forEach(function(item) {
          let message = 'Field label cannot be empty.'
          if(item.label == ''){
            if(item.type == 'staticText'){
              message = 'Static message cannot be empty.'
            }
            that.$toasted.clear()
            that.$toasted.global.general_error({
              message : message
            })
            return false
          }
          item.isActive = false
        })
      }
    },
    async checkIntroVideo(){
      let showModal = false
      // let showModal = true
      // this.user.me.companySettings.watchIntroVideos.forEach(video => {
      //   if(video == 'leadform-edit'){
      //     showModal = false
      //   }
      // })

      if(showModal){
        this.isShowVideoModal = true
      }
    },
    showVideoModal(){
      this.isShowVideoModal = true
    },
    closeVideoModal(){
      this.isShowVideoModal = false
    },
	},
}
</script>
